import React, { useState } from "react";
import { Container, Box, Typography, TextField, Button } from "@mui/material";

function App() {
  const [input, setInput] = useState("");
  const [result, setResult] = useState("");

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call your AI API or function here and update the result
    // For now, we just display the input text
    setResult(`Result: ${input}`);
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          AIChacha - AI App
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          id="inputText"
          label="Enter your text"
          value={input}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </form>
      <Box sx={{ mt: 4 }}>
        {result && (
          <Typography variant="h6" component="p">
            {result}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default App;
